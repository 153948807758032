import React, { useRef, useState, useEffect } from 'react'
import FolderContainer from "../../components/folderContainer"
import Slider from "react-slick"
import './index.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Homepage = ({ icons, openBatterie, openCalendrier, openPopup, closePopup, openPage, steps }) => {
    const [currentFolder, setCurrentFolder] = useState({
        title: null,
        content: null
    })

    const ref = useRef()
    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: 1,
        arrows: false,
        speed: 0,
        centerMode: false,
        fade: false,
        waitForAnimate: false,
        ease: 'ease-in',
        lazyLoad: 'progressive',
        slidesToScroll: 1,
        mobileFirst: true
    }

    useEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    }, [ref])

    useEffect(() => {
        openBatterie()
    }, [])

    return (
        <main ref={ref} className={`homepage`}>
            <Slider {...settings}>
                <FolderContainer
                    steps={steps}
                    openPage={openPage}
                    closePopup={closePopup}
                    openPopup={openPopup}
                    openCalendrier={openCalendrier}
                    setCurrentFolder={setCurrentFolder}
                    icons={icons.slice(0, 16)} />
              </Slider>
        </main>
    )
}

export default Homepage