import React from 'react'
import closeIcon from '../../../assets/icons/close.png'
import Locker from '../../../assets/icons/locker.png'

const LockScreen = ({ onClick, checkPassword, lockedScreen, textInput, setTextInput }) => {
    return (
        <section className="lockScreenContainer">
            <section ref={lockedScreen} className="lockScreen">
                <img className="close" onClick={onClick} src={closeIcon} alt="" />
                <img className="locker" src={Locker} alt="" />
                <p>Contenu sécurisé</p>
                <input type="text" onSubmit={checkPassword} placeholder="Mot de passe" value={textInput} onChange={(e) => setTextInput(e.target.value)} />
                <p>Pour ouvrir ce fichier, merci d’entrer le code de sécurité</p>
                <input type="submit" onClick={e => {
                    e.preventDefault()
                    checkPassword()
                }} value="Valider" />
            </section>
        </section>
    )
}

export default LockScreen