import React, { useState, useEffect, useRef } from 'react'
import TopBar from '../../../components/topBar'
import BackIcon from '../../../assets/icons/back.png'
import TrackItem from './TrackItem'
import PageTransition from '../../../components/utils/PageTransition'
import LockScreen from '../LockScreen'
import './index.scss'

const AlbumHidden = ({ close, openPage, closePage, batterie, tracks, setTracks }) => {
    const [player, setPlayer] = useState({
        song: null,
        isPlaying: false,
        audioId: null
    })
    
    const [textInput, setTextInput] = useState('')
    const [isOpenLockScreen, setIsOpenLockScreen] = useState(false)
    const [currentLockedTrack, setCurrentTrack] = useState(null)

    const lockedScreen = useRef()

    const unlockTrack = (track) => {
        const updatedTracks = [...tracks];
        updatedTracks[updatedTracks.findIndex(x => x.id == track.id)].locked = false;
        setTracks(updatedTracks);
      }

    const checkPassword = (track) => {
        if (textInput.toLowerCase() === track.password.toLowerCase()) {
            unlockTrack(track)
            setIsOpenLockScreen(false)
            setTextInput('')
        } else if (textInput.toLowerCase() === "hello world") {
            openPage('easterEgg')
        } else {
            lockedScreen.current.classList.add('wiggle')

            setTimeout(() => {
                lockedScreen.current.classList.remove('wiggle')
            }, [1000])
        }
    }

    // useEffect(() => {
    //     if (player.song && player.isPlaying) {
    //         player.song.play()
    //     }
    // }, [player.isPlaying, player.song])

    useEffect(() => {
        if (player.song) {
            player.song.pause()
        }

        if (player.isPlaying && player.audioId) {
            player.song.play()
        }
    }, [player.isPlaying, player.audioId])

    return (
        <section className="dixSeptPourcentContent">
            <PageTransition stateIn={isOpenLockScreen}>
                <LockScreen
                    checkPassword={() => checkPassword(currentLockedTrack)}
                    setTextInput={setTextInput}
                    textInput={textInput}
                    lockedScreen={lockedScreen}
                    currentLockedTrack={currentLockedTrack}
                    onClick={() => setIsOpenLockScreen(false)}
                />
            </PageTransition>

            <TopBar batterie={batterie} color="black" />
            <div className="topMusic">
                <img onClick={() => {
                    close()
                    if (player.song) {
                        player.song.pause()
                    }
                }} className="back" src={BackIcon} alt="" />
                <h2>17%</h2>
            </div>

            <ul>
                {
                    tracks.map((track) => {
                        return (
                            <TrackItem
                                track={track}
                                key={track.id}
                                setIsOpenLockScreen={setIsOpenLockScreen}
                                id={track.id}
                                title={track.name}
                                artiste={track.author}
                                song={track.file}
                                setCurrentTrack={setCurrentTrack}
                                unlockTrack={unlockTrack}
                                setPlayer={setPlayer}
                                password={track.password}
                                player={player}
                        />
                        )
                    })
                } 
            </ul>
        </section>
    )
}

export default AlbumHidden