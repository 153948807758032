import React from 'react'
import DixSeptPourcentCover from '../../../assets/img/FIFOU-BLAKHAT-COVER-LETO.jpg'
import './index.scss'

const BigPicture = () => {
    return (
        <a className="bigPicture" href="https://leto.lnk.to/toutrecommencer" target="_blank" rel="noreferrer">
            <img src={DixSeptPourcentCover} alt="" />
            <p>Tout recommencer</p>
        </a>
    )
}

export default BigPicture