import React, { useState } from 'react'
import TopBar from '../../components/topBar'
import './index.scss'
import BackIcon from '../../assets/icons/back.png'
import MusicItem from './MusicItem'
import TrapstarCover from '../../assets/img/trapstar.jpg'
import Trapstar2Cover from '../../assets/img/trapstar2.png'
import CentVisagesCover from '../../assets/img/100visages.jpg'
import BigPicture from './BigPicture'

const Music = ({ close, batterie }) => {
    const [albums] = useState([
        {
            id: 1,
            name: '100 visages',
            cover: CentVisagesCover,
            spotifyURL: "https://leto.lnk.to/100visages"
        },
        {
            id: 2,
            name: 'TRAP$TAR 2',
            cover: Trapstar2Cover,
            spotifyURL: "https://leto.lnk.to/trapstar2"
        },
        {
            id: 3,
            name: 'TRAP$TAR',
            cover: TrapstarCover,
            spotifyURL: "https://leto.lnk.to/trapstar"
        }
    ])

    return (
        <main className={`music`}>
            <TopBar batterie={batterie} color="black" />
            <div className="topMusic">
                <img onClick={close} className="back" src={BackIcon} alt="" />
                <h2>Bibliothèque</h2>
            </div>
            <section className="musicList">
                <BigPicture />
                {albums.map((album) => <MusicItem key={album.id} data={album} />)}
            </section>
        </main>
    )
}

export default Music