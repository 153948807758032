import Call from '../../assets/icons/call.png'
import SMSIcon from '../../assets/icons/SMS.png'
import './index.scss'
import MusicIcon from "../../assets/icons/music.png"
import Maps from "../../assets/icons/maps.png"

const BottomBar = ({ close, open, steps, setSteps }) => {
    return (
        <section className="bottomBar">
            <img onClick={() => open('call')} src={Call} alt="" />
            <img onClick={() => open('maps')} src={Maps} alt="" />
            <div className={`smsContainer ${steps.phoneNotification === true ? 'notif' : ''}`}>
            <img onClick={() => {
                open('sms')
                setSteps((prevState) => ({
                    ...prevState,
                    phoneNotification: false
                }))
                close('notificationSMS')
            }} src={SMSIcon} alt="" />
            </div>
            <img onClick={() => open('music')} src={MusicIcon} alt="" />
        </section>
    )
}

export default BottomBar