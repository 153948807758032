import React, { useEffect, useState } from 'react'
import TopBar from '../../components/topBar'
import './index.scss'
import Sun from '../../assets/icons/sun.png'
import MeteoBg from '../../assets/icons/meteoBg.jpg'
import Back from '../../assets/icons/back.png'



const Meteo = ({ batterie, close }) => {
    const [jours, setMeteo] = useState(['Maint.'])
    useEffect(() => {
        let _jours = [...jours]
        for (let i = 0; i < 5; i++) {
            _jours.push(`${new Date().getHours() + 1 + i > 24 ? new Date().getHours() + 1 + i - 24 : new Date().getHours() + 1 + i}h`)
        }
        setMeteo(_jours)
    }, [])

    return (
        <main style={{
            backgroundImage: `url(${MeteoBg})`
        }} className="meteo">
            <img className="back" src={Back} onClick={close} alt="Revenir sur la homepage" />
            <TopBar batterie={batterie} />
            <div className="mainMeteo">
                <p className="city">Porte de Saint-Ouen</p>
                <p className="temps">Ensoleillé</p>
                <p className="temperature">17°</p>
                <p className="minMax">Max. 17° Min. 17°</p>
            </div>

            <div className="nextHours">
            {
                 jours.map((jour, index) => (
                    <div className={`hour ${index === 0 ? 'bold' : ''}`}>
                        <p>{jour}</p>
                        <img src={Sun} alt="" />
                        <p>17°</p>
                    </div>
                 ))
            }
            </div>
        </main>
    )
}

export default Meteo