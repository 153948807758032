import React, { useEffect, useState } from 'react'
import TopBar from '../../components/topBar'
import BackIcon from '../../assets/icons/back.png'
import PlayIcon from '../../assets/icons/play.png'
import PauseIcon from '../../assets/icons/pause.png'
import Song1 from '../../assets/songs/LETO-17-POURCENT-extrait-accap.mp3'
import Song2 from '../../assets/songs/LETO-extrait-accap-mozart-capitaine.mp3'
import Song3 from '../../assets/songs/LETO-JUDO-ACAP.mp3'
import './index.scss'



const Dictaphone = ({ batterie, close }) => {
    const [input, setInput] = useState('')
    const [player, setPlayer] = useState({
        audioId: null,
        song: null,
        isPlaying: false
    })
    const [acaps, setAcaps] = useState([
        {
            id: 1,
            title: 'Enregistrement 1',
            file: Song1,
            hour: '17:17',
            duration: '00:06'
        },
        {
            id: 2,
            title: 'Enregistrement 2',
            file: Song2,
            hour: '17:18',
            duration: '00:01'
        },
        {
            id: 3,
            title: 'Enregistrement 3',
            file: Song3,
            hour: '17:20',
            duration: '02:00'
        }
    ])

    useEffect(() => {
        console.log(player)
        player.song && player.song.pause()

        if (player.isPlaying && player.song) {
            player.song.play()
        }
    }, [player.song, player.isPlaying])

   return (
        <main className="dictaphone">
            <TopBar color="black" batterie={batterie} />
            <div className="topDictaphone">
                <div className="top">
                    <img onClick={() => {
                        close()
                        player.song && player.song.pause()
                    }} className="back" src={BackIcon} alt="" />
                    <h2>Tous les<br />
                    enregistrements</h2>
                </div>
                <div className="inputContainer">
                    <input placeholder="Rechercher" value={input} onChange={(e) => setInput(e.target.value)} type="text" />
                </div>
            </div>

            <div className="dictaphoneContent">
                {
                    acaps.filter((acap) => acap.title.toLowerCase().includes(input.toLowerCase())).map((acap) => {
                        return (
                            <article className="articleDictaphone" key={acap.id}>
                                <h3>{acap.title}</h3>
                                <div className="infos">
                                    <p className="hour">{acap.hour}</p>
                                    <p className="duration">{acap.duration}</p>
                                </div>

                                <img className="play" onClick={() => {
                                            if (player.isPlaying && player.audioId === acap.id) {
                                                setPlayer(prevState => ({
                                                    ...prevState,
                                                    isPlaying: false
                                                }))
                                            } else if (player.audioId !== acap.id) {
                                                player.song && player.song.pause()
                                                setPlayer({
                                                    song: new Audio(acap.file),
                                                    isPlaying: true,
                                                    audioId: acap.id
                                                })
                                            } else if (player.isPlaying === false && player.audioId === acap.id) {
                                                setPlayer(prevState => ({
                                                    ...prevState,
                                                    isPlaying: true
                                                }))
                                            } else {
                                                setPlayer(prevState => ({
                                                    ...prevState,
                                                    isPlaying: false
                                                }))
                                            }
                                        }} src={player.isPlaying && player.audioId === acap.id ? PauseIcon : PlayIcon} />
                            </article>
                        )
                    })
                }
                {
                    acaps.filter((acap) => acap.title.toLowerCase().includes(input.toLowerCase())).length === 0 && <p>Aucun enregistrement audio trouvé</p>
                }
            </div>
            
        </main>
    )
}

export default Dictaphone