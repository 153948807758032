import React, { useState } from 'react'
import TopBar from '../../components/topBar'
import './index.scss'
import BackIcon from '../../assets/icons/back.png'
import SMSItem from './SMSItem'

const SMS = ({ close, batterie }) => {
    const [currentConv, setCurrentConv] = useState(null)
    const [isOpenConv, setIsOpenConv] = useState(false)
    const [items] = useState([
    {
        name:"Fazz",
        avatar: "F",
        date: "Maintenant",
        id: 1,
        messages: [
            {
                author: "other",
                text: "Wsh Leto, tout est bon pour la soirée. Ça sera bien le mercredi 15 septembre à 20h au Badaboum, ils sont pas prêts !"
            }
        ]
    },
    {
        name:"Taslym",
        avatar: "T",
        date: "hier",
        id: 2,
        messages: [
            {
                author: "other",
                text: "Yo Leto, c’est quoi le mot de passe du son de l’autre fois déjà ?"
            },
            {
                author: "me",
                text: "Badaboum"
            }
        ]
    },

    {
        name:"Fifou",
        avatar: "F",
        date: "hier",
        id: 3,
        messages: [
            {
                author: "me",
                text: "Ca dit quoi Fifou ? T’as les photos du Shoot dans le 17 ?"
            },
            {
                author: "other",
                text: "Yo Leto ! Je viens de t’envoyer ça"
            },
            {
                author: "me",
                text: "Enervé Fifou merci !"
            }
        ]
    },
    {
        name:"Fred",
        avatar: "F",
        date: "hier",
        id: 4,
        messages: [
            {
                author: "other",
                text: "Salut Leto, on se capte pour le Planète Rap du 13 au 17 septembre, c’est tout bon pour toi ?"
            },
            {
                author: "me",
                text: "Carrément mon ami !!"
            },
            {
                author: "other",
                text: "Au fait comment je fais pour écouter le son que tu m’as envoyé ?"
            },
            {
                author: "me",
                text: "Tape PSO"
            },
            {
                author: "other",
                text: "Bien vu merci Leto !"
            }
        ]
    },
    {
        name:"Hamza",
        avatar: "H",
        date: "hier",
        id: 5,
        messages: [
            {
                author: "me",
                text: "Lourd lourd lourd le son"
            },
            {
                author: "other",
                text: "Ouais ouais, on a tué ça capitaine"
            },
            {
                author: "me",
                text: "Ça sera sur l’album sûr"
            }
        ]
    },

    {
        name:"Rihanna",
        avatar: "R",
        date: "hier",
        id: 6,
        messages: [
            {
                author: "other",
                text: "Hey Leto, What’s up ?"
            },
            {
                author: "me",
                text: "Hey Riri, what you up to ?"
            },
            {
                author: "other",
                text: "Just got back home, I’m trying to listen to the track you sent me, what’s the password ?"
            },
            {
                author: "me",
                text: "It’s MozartCapitaineJackson 😉"
            },
            {
                author: "other",
                text: "Thanks Capitaine"
            },
            {
                author: "me",
                text: "Ok Bisou"
            }
        ]
    },
    {
        name:"Rec. 118",
        avatar: "118",
        date: "hier",
        id: 7,
        messages: [
            {
                author: "other",
                text: "Salut Leto, l'album est prêt, sortie le 17 septembre comme prévu"
            },
            {
                author: "me",
                text: "C'est carré les amis !"
            }
        ]
    },
    {
        name:"Tiakola",
        avatar: "T",
        date: "hier",
        id: 8,
        messages: [
            {
                author: "other",
                text: "Tu as le mot de passe du son gros ?"
            },
            {
                author: "me",
                text: "« Bisou »"
            },
            {
                author: "other",
                text: "On va les faire paniquer, ça va péter ce son là"
            },
            {
                author: "me",
                text: "Les papiers les Mapessas mon ami !"
            }
        ]
    },

    {
        name:"Booska-P",
        avatar: "BP",
        date: "hier",
        id: 9,
        messages: [
            {
                author: "other",
                text: "Salut Leto, toujours op pour tourner le freestyle aujourd'hui ?"
            },
            {
                author: "me",
                text: "C’est carré"
            }
        ]
    },
    {
        name:"Cheu-B",
        avatar: "CB",
        date: "07/09/2021",
        id: 10,
        messages: [
            {
                author: "me",
                text: "On arrive avec Kepler"
            },
            {
                author: "other",
                text: "Vas-y venez on est au studio"
            }
        ]
    }, 
    {
        name:"Kepler",
        avatar: "K",
        date: "07/09/2021",
        id: 11,
        messages: [
            {
                author: "other",
                text: "Wsh Leto t’es dans le 17 ?"
            },
            {
                author: "me",
                text: "Ouai ouai ouai Kepler on s’attrape"
            }
        ]
    },
    {
        name:"Pykos",
        avatar: "P",
        date: "07/09/2021",
        id: 12,
        messages: [
            {
                author: "other",
                text: "Leto ça dit quoi ? T’as le mot de passe du track ?"
            },
            {
                author: "me",
                text: "C’est Anakin mon ami"
            },
            {
                author: "other",
                text: "Archi lourd le son ! Capitaine fait de l’art comme Mozart encore et toujours"
            }
        ]
    },
    {
        name:"MHD",
        avatar: "M",
        date: "06/09/2021",
        id: 13,
        messages: [
            {
                author: "other",
                text: "T’es dans quelle ambiance pour le son ?"
            },
            {
                author: "me",
                text: "Ambiance Christian Dior"
            },
            {
                author: "other",
                text: "Bien vu mon gars c’est parti"
            }
        ]
    },
    {
        name:"Maes",
        avatar: "M",
        date: "06/09/2021",
        id: 14,
        messages: [
            {
                author: "other",
                text: "Wesh Leto, Studio aujourd’hui ou quoi ?"
            },
            {
                author: "me",
                text: "De fou c'est parti"
            }
        ]
    },
    {
        name:"Inconnu",
        avatar: "I",
        date: "06/09/2021",
        id: 15,
        messages: [
            {
                author: "other",
                text: "Incroyable Mozart Capitaine Jackson !! T’as tout cassé capitaine"
            },
            {
                author: "me",
                text: "C’est qui ? Je viens de changer de téléphone"
            }
        ]
    }])

    const closeConversation = () => {
        setCurrentConv(null)
        setIsOpenConv(false)
    }

    return (
        <main className={`sms`}>
            <TopBar batterie={batterie} color="black" />
            <div className="topSMS">
                <img onClick={close} className="back" src={BackIcon} alt="" />
                <h2>Messages</h2>
            </div>
            <section className="smsList">
                {items.map((item) => {
                    return (
                        <SMSItem key={item.id} setCurrentConv={setCurrentConv} setIsOpenConv={setIsOpenConv} data={item} />
                    )
                })}
            </section>



            <section className={`conversation ${isOpenConv ? 'open' : ''}`}>
                {
                    currentConv && (
                        <>
                        <div className="topSMS">
                            <TopBar color="black" batterie={batterie} />
                            <img onClick={closeConversation} className="back" src={BackIcon} alt="" />
                            
                            <div className="nameAndAvatar">
                                <div className="avatar">
                                    <p>{currentConv.avatar}</p>
                                </div>
                                <p>{currentConv.name}</p>
                            </div>
                        </div>
                        {currentConv && (
                            <section className='messages'>
                                {currentConv.messages.map((message, index) => (
                                    <div key={index} className={`message ${message.author}`}>
                                    <p>{message.text}</p>
                                </div>
                                ))}
                            </section>
                        )}
                        </>
                    )
                }
            </section>
        </main>
    )
}

export default SMS