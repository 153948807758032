import React, { useEffect } from 'react'
import './index.scss'
import playIcon from '../../../../assets/icons/play.png'
import pauseIcon from '../../../../assets/icons/pause.png'
import Locker from '../../../../assets/icons/locker.png'

const TrackItem = ({ setIsOpenLockScreen, setCurrentTrack, track, player, id, setPlayer, song, artiste, title, password }) => {
    return (
        <li className="trackItem" onClick={() => {
            if (track.locked) {
                setCurrentTrack(track)
                setIsOpenLockScreen({
                    isOpen: true,
                    track
                })
            }
        }
        }>
            <article>
                {
                    track.locked && <img onClick={
                        () => {
                            setCurrentTrack(track)
                            setIsOpenLockScreen({
                                isOpen: true,
                                track
                            })
                        }
                    } src={Locker} alt="" />
                }
                {
                    !track.locked && (<img onClick={() => {
                        if (player.song) {
                            player.song.pause()
                        }

                        if (player.audioId === id) {
                            if (player.isPlaying) {
                                setPlayer(prevState=> ({ ...prevState, song: new Audio(track.file), audioId: id, isPlaying: false }))
                            } else {
                                setPlayer(prevState => ({ ...prevState, song: new Audio(track.file), isPlaying: true }))
                            }
                        } else {
                            setPlayer(prevState => ({ ...prevState, song: new Audio(track.file), audioId: id, isPlaying: true }))
                        }
                    }} src={player.audioId === id && player.isPlaying ? pauseIcon : playIcon} alt="" />)
                }
                <div className="mainInfos">
                    <p className="title">{title}</p>
                    <p className="artiste">{artiste}</p>
                </div>
            </article>
            <p className="hour">00:17</p>
        </li>
    )
}

export default TrackItem