import React from 'react'
import TopBar from '../../components/topBar'
import './index.scss'
import BackIcon from '../../assets/icons/back.png'
import CallItem from './CallItem'

const Call = ({ close, batterie }) => (
    <main className={`call`}>
        <TopBar batterie={batterie} color="black" />
        <div className="content">
            <div className="topCall">
                <img onClick={close} className="back" src={BackIcon} alt="" />
                <h2>Récents</h2>
            </div>
            <section className="callList">
                <CallItem name="Maes" type="miss" date="hier" />
                <CallItem name="Tiakola" type="external" date="hier" />
                <CallItem name="Fazz" type="external" date="hier" />
                <CallItem name="Fazz" type="miss" date="hier" />
                <CallItem name="MHD" type="miss" date="hier" />
                <CallItem name="Rihanna" type="miss" date="lundi" />
                <CallItem name="Cheu-B" type="external" date="lundi" />
                <CallItem name="Kepler" type="external" date="dimanche" />
                <CallItem name="Nicolas Sarkozy" type="external" date="31/08/2021" />
                <CallItem name="Hamza" type="miss" date="30/08/2021" />
                <CallItem name="Fazz" type="external" date="30/08/2021" />
                <CallItem name="Pikos" type="external" date="30/08/2021" />
                <CallItem name="Hamza" type="external" date="30/08/2021" />
            </section>
        </div>
    </main>
)

export default Call