import React from 'react'
import TopBar from '../../components/topBar'
import BackIcon from '../../assets/icons/back.png'
import './index.scss'


const Notes = ({ batterie, close }) => {
    return (
        <main className="notes">
            <TopBar color="black" batterie={batterie} />
            <div className="topCall">
                <img onClick={close} className="back" src={BackIcon} alt="" />
                <h2>Notes</h2>
            </div>
            <div className="mainNotes">
                <h2>17%</h2>
                <p>
                17% mon ami<br /><br />
                C’est Mozart Capitaine Jackson Five<br /><br />
                Double Bang<br /><br />
                Anakin !!<br /><br />
                J’suis qu’à 17%, pourtant,<br /><br />
                t’as l’impression qu’ça rafale à bout portant<br /><br />
                J’te sors un autre album pépère,<br /><br />
                comme la violence dans nos ghettos j’suis partout<br /><br />
                On reste fidèle au poste, garde la pêche,<br /><br />
                j’me rappelle quand j’avais rien dans les poches<br /><br />
                Maintenant tu m’jettes l’œil quand je jump dans le porsch<br /><br />
                Nan, Nan, j’peux pas louper le coche<br /><br />
                J’t’envoie mon rib si tu payes pas en cash<br /><br />
                On veut tourner la page<br /><br />
                mais tu sais qu’en bas le train de vie nous forge une mauvaise carapace<br /><br />
                Hein, quand j’arrive faites de la place<br /><br />
                Tu reconnais l’moteur quand mon gamos il passe
                </p>
            </div>
        </main>
    )
}

export default Notes