import './index.scss'
import React from 'react'

const Popup = ({
    title,
    text,
    close,
    isOpen
}) => {
    return (
        <div style={{
            display: isOpen ? "flex" : "none"
        }} className='popupcontainer'>
            <section className="popup">
                <h2>{title}</h2>
                <p>{text}</p>
                <button onMouseUp={close} onClick={close} className="close"><p>Fermer</p></button>
            </section>
        </div>
    )
}

export default Popup