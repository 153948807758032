import React, { useState, useEffect, useRef } from 'react'
import BackIcon from '../../assets/icons/back.png'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import './index.scss'
import TopBar from '../../components/topBar';

mapboxgl.accessToken = 'pk.eyJ1Ijoid2FybmVybXVzaWNmcmFuY2UiLCJhIjoiY2p0aWplZXd3MTRwZjQwbGhuNG12Mm0ybSJ9.LrneL3ZdUPgxBcBrg9FhQQ';

const bounds = [
    [2.3215279, 48.8847089], // Southwest coordinates
    [2.330957, 48.9203475] // Northeast coordinates
];

const Maps = ({ close, batterie }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(2.3285798);
    const [lat, setLat] = useState(48.8973727);
    const [zoom, setZoom] = useState(16);

    useEffect(() => {
        if (map.current) return; // initialize map only once
            map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: zoom,
            maxBounds: bounds
        });
    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize

        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

    return (
        <main className={`maps`}>
            <TopBar batterie={batterie} color="black" />
            <div className="topMaps">
                <img onClick={close} className="back" src={BackIcon} alt="" />
                <h2>Maps</h2>
            </div>
            <div className="mapsContent">
                <div ref={mapContainer} className="map-container" />
            </div>
        </main>
    )
}

export default Maps