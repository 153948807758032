import React from 'react'
import ExternalCallIcon from '../../../assets/icons/externalCall.png'
import './index.scss'

const CallItem = ({ name, date, type }) => {
    return (
        <article className="callItem">
            {
                type === "external" && <img className="externalIcon" src={ExternalCallIcon} alt="" />
            }
            <div className="mainInfos">
                <h2 className={`${type === "miss" ? "miss" : ""}`}>{name}</h2>
                <p>téléphone</p>
            </div>

            <p className="date">{date}</p>
        </article>
    )
}

export default CallItem