import { CSSTransition } from "react-transition-group"

const PageTransition = ({ stateIn, children }) => (
    <CSSTransition
        in={stateIn}
        timeout={350}
        classNames="example"
        unmountOnExit
        appear
    >
        {children}
    </CSSTransition>
)

export default PageTransition