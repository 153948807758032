import './index.scss'
import React from 'react'
import animationIntroduction from '../../assets/img/introduction.gif'

const Introduction = () => {
   return (
       <main className="introduction">
           {/* <img className="logo" src={Logo} alt="Logo de Leto" /> */}
           {/* <p>Passer en plein écran pour une expérience plus immersive.</p>
           <img className="fullscreen" src={FullScreen} alt="Cliquer pour ouvrir en plein écran" /> */}
           {/* <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
           <img src={animationIntroduction} alt="" />
       </main>
   ) 
}

export default Introduction