import React, { useEffect, useState } from 'react'
import Logo from '../../assets/img/Leto_Logo.png'
import LowBatterie from '../../assets/img/lowBatterie.png'
import './index.scss'

const EndGame = () => {
    const [step, setStep] = useState(1)
    useEffect(() => {
        setTimeout(() => {
            setStep(2)
        }, 3000)
    }, [])

    return (
        <main className={`endgame ${step === 2 ? 'step2' : 'step1'}`}>
            <img className="lowBatterie" src={LowBatterie} alt="" />

            <img className="logo" src={Logo} alt="" />
            <button onClick={() => window.location.reload()}>Recommencer ?</button>
            <a href="https://leto.lnk.to/PLEM" target="_blank" rel="noreferrer">Écouter en stream</a>
            <a href="https://leto.lnk.to/17pour100-D2C" target="_blank" rel="noreferrer">Voir le store</a>
        </main>
    )
}

export default EndGame