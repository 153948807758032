import React from 'react'
import './index.scss'

const MusicItem = ({ data }) => {
    return (
        <a href={data.spotifyURL} target="_blank" rel="noreferrer" className="MusicItem">
            <img className="cover" src={data.cover} alt="" />
            <p className="date">{data.name}</p>
        </a>
    )
}

export default MusicItem