import React from 'react'
import './index.scss'

function truncate(source, size) {
return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

const SMSItem = ({ data, setIsOpenConv, setCurrentConv }) => {
    return (
        <article onClick={() => {
            setIsOpenConv(true)
            setCurrentConv(data)
        }} className="SMSItem">
            <div className="avatar">
                <p>{data.avatar}</p>
            </div>
            <div className="mainInfos">
                <h2>{data.name}</h2>
                <p className="textInfo">{truncate(data.messages[data.messages.length-1].text, 48)}</p>
            </div>

            <p className="date">{data.date}</p>
        </article>
    )
}

export default SMSItem