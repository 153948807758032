import React, { useState } from 'react'
import CloseIcon from '../../assets/icons/close.png'
import TopBar from '../../components/topBar'
import BackIcon from '../../assets/icons/back.png'
import Img1 from '../../assets/img/LETO@FIFOU-3396.jpg'
import Img2 from '../../assets/img/LETO@FIFOU-3417.jpg'
import Img3 from '../../assets/img/LETO@FIFOU-3702.jpg'
import Img4 from '../../assets/img/mockup-CD+tee-d2c.png'
import Img5 from '../../assets/img/onepiece.jpg'
import Img6 from '../../assets/img/alleyes.png'
import Img7 from '../../assets/img/zoro.jpg'
import Img8 from '../../assets/img/logo-dior-300x300.jpg'
import Img9 from '../../assets/img/Logo-Prada.jpg'
import Img10 from '../../assets/img/image-106.jpg'
import Img11 from '../../assets/img/image-107.jpg'
import Img12 from '../../assets/img/image-108.jpg'
import Img13 from '../../assets/img/image-109.jpg'
import Img14 from '../../assets/img/image-110.jpg'
import Img15 from '../../assets/img/soiree.jpeg'
import Img16 from '../../assets/img/Franklin_Saint_1.jpg'
import './index.scss'

const Gallerie = ({ close, batterie }) => {
    const [state, setState] = useState({
        pictures: [
            {
                id: 14,
                url: Img15
            },
            {
                id: 1,
                url: Img1
            },
            {
                id: 9,
                url: Img9
            },
            {
                id: 3,
                url: Img3
            },
            {
                id: 4,
                url: Img4
            },
            {
                id: 5,
                url: Img5
            },
            {
                id: 2,
                url: Img2
            },
            {
                id: 6,
                url: Img6
            },
            {
                id: 12,
                url: Img13
            },
            {
                id: 7,
                url: Img7
            },
            {
                id: 8,
                url: Img8
            },
            {
                id: 11,
                url: Img12
            },
            {
                id: 9,
                url: Img10
            },
            {
                id: 10,
                url: Img11
            },
            {
                id: 13,
                url: Img14
            },
            {
                id: 15,
                url: Img16
            }
        ],
        currentPicture: null
    })

    return (
        <main className={`gallerie`}>
            <TopBar batterie={batterie} color="black" />
            <div className="topGallerie">
                <img onClick={close} className="back" src={BackIcon} alt="" />
                <h2>Photos</h2>
            </div>
            {state.currentPicture !== null && <div className="blurryBg" onClick={() => setState({ ...state, currentPicture: null })}><img className="close" src={CloseIcon} onClick={() => setState({ ...state, currentPicture: null })} alt="" /><img className="bigPicture" src={state.currentPicture} alt="" /></div>}
            <section className={`gallerieContainer ${state.currentPicture === null ? '' : 'blury'}`}>
            {
                state.pictures.map((picture) => {
                    return (
                        <article key={picture.id}>
                            <img onClick={() => setState({ ...state, currentPicture: picture.url })} src={picture.url} alt="" />
                        </article>
                    )
                })
            }
            </section>
        </main>
    )
}

export default Gallerie