import React, { useEffect, useRef } from 'react'
import Call from '../../assets/icons/call.png'
import NotifSong from '../../assets/songs/iphone-notif.mp3'
import './index.scss'

const Notification = ({ onClick, appName, title, text, onMount, setOpenState, icon }) => {
    const notificationRef = useRef()

    const swipeUp = (eTouch, eMove) => {
        if (eTouch.clientY > eMove.clientY) {
            setOpenState(prevState => ({
                ...prevState,
                notificationSMS: false
            }))
        }
    }

    const setScrolltop = (eTouch) => {
        notificationRef?.current?.addEventListener('mousemove', (eMove) => {
            swipeUp(eTouch, eMove)
        })
        notificationRef?.current?.addEventListener('touchmove', (eMove) => {
            swipeUp(eTouch, eMove)
        })
    }
    
    useEffect(() => {
        const audioNotif = new Audio(NotifSong)
        audioNotif.play()

        onMount && onMount()

        notificationRef?.current?.addEventListener('touchdown', (eTouch) => setScrolltop(eTouch))
        notificationRef?.current?.addEventListener('mousedown', (eTouch) => setScrolltop(eTouch))
    }, [])

    return (
        <div onClick={onClick} ref={notificationRef} className="notification">
            <div className="topNotif">
                <div className="left">
                    <img src={icon} alt="" />
                    <p>{appName}</p>
                </div>

                <p>Maintenant</p>
            </div>

            <h2>{title}</h2>
            <p>{text}</p>
        </div>
    )
}

export default Notification