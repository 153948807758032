import React, { useEffect, useRef } from 'react'
import CalendrierImg from '../../assets/img/calendrier.png'
import BackIcon from '../../assets/icons/back.png'
import './index.scss'
import TopBar from '../../components/topBar'

const Calendrier = ({ close, openPage, closePage, batterie }) => {
    const calRef = useRef()
    return (
            <main className={`calendrier`}>
                <TopBar batterie={batterie} color="black" />
                <div className="topCalendrier">
                    <img onClick={close} className="back" src={BackIcon} alt="" />
                    <h2>Calendrier</h2>
                </div>
                <div className="calendrierContent">
                    <button
                        style={{
                            top:  calRef?.current?.height * 0.135 + 140 + "px"
                        }}
                        className="inscriptionShowcase"
                        onClick={() => {
                            openPage('dayCalendrier')
                        }}
                    >

                    </button>
                    <button
                        style={{
                            top:  calRef?.current?.height * 0.52 + 140 + "px"
                        }}
                        type="button" className="wmg-button inscriptionShowcase" 
                        data-platform="form">
                    </button>
                    <img ref={calRef} src={CalendrierImg} alt="" />
                </div>
            </main>
    )
}

export default Calendrier