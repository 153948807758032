import React, { useEffect, useState, useRef } from 'react';
import { Switch, Route } from 'react-router-dom'
import Homepage from './pages/Homepage';
import Introduction from './pages/Introduction';
import TopBar from './components/topBar';
import EndGame from './pages/EndGame';
import BottomBar from './components/bottomBar';
import Popup from './components/popup';
import Gallerie from './pages/Gallerie';
import Contact from './pages/Contact';
import Call from './pages/Call';
import SMS from './pages/SMS';
import AgendaIcon from './assets/icons/calicon.png'
import SMSIcon from './assets/icons/SMS.png'
import './reset.scss';
import Music from './pages/Music';
import Calendrier from './pages/Calendrier';
import BreakedGlass from './assets/icons/breakedGlass.png'
import PageTransition from './components/utils/PageTransition';
import ReglagesIcon from './assets/icons/reglages.png'
import MailIcon from './assets/icons/mail.png'
import SafariIcon from './assets/icons/safari.png'
import Maps from './pages/Maps';
import AlbumHidden from './pages/Music/AlbumHidden';
import Notification from './components/notification';
import LogoLeto from './assets/img/Leto_Logo.png'
import { CSSTransition } from "react-transition-group";
import DayCalendrier from './pages/Calendrier/DayCalendrier';
import Meteo from './pages/Meteo';
import Notes from './pages/Notes';
import Dictaphone from './pages/Dictaphone';
import Footer from './components/footer';
// import Lydia from './pages/Lydia';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const App = () => {
  const [batterie, setBatterie] = useState(17)
  const [icons] = useState([
    {
      name: "Mail",
      type: "fake",
      popupTitle: "Erreur",
      popupText: "Batterie trop faible",
      img: MailIcon,
      id: 1
    },
    {
        name: "Notes",
        type: "notes",
        id: 10
    },
    {
        name: "Dictaphone",
        type: "dictaphone",
        id: 7
    },
    {
      name: "Réglages",
      type: "fake",
      popupTitle: "Erreur",
      popupText: "Batterie trop faible",
      img: ReglagesIcon,
      id: 4
    },
    {
        type: "gallerie",
        id: 8
    },
    {
        name: "Safari",
        type: "fake",
        popupTitle: "Erreur",
        popupText: "Batterie trop faible",
        img: SafariIcon,
        id: 5
    },
    {
        name: "Météo",
        type: "meteo",
        id: 15
    },
    {
        type: "void",
        id: 6
    },
    {
        type: "void",
        id: 2
    },
    {
        type: "lockedAlbum",
        id: 16
    },
    {
        name: "Calendrier",
        type: "calendrier",
        id: 14
    },
    {
        type: "void",
        url: "/archives",
        id: 3
    },
    {
        type: "void",
        id: 17
    },
    {
        type: "void",
        id: 18
    },
    {
        type: "void",
        id: 19
    },
    {
        type: "void",
        id: 18
    },
])
  const [isLoaded, setIsLoaded] = useState(false)
  const [end, setEnd] = useState(false)
  const [openState, setOpenState] = useState({
    call: false,
    sms: false,
    gallerie: false,
    music: false,
    calendrier: false,
    dayCalendrier: false,
    batterie: false,
    popup: false,
    easterEgg: false,
    notificationAgenda: false,
    notificationSMS: false,
    lockedAlbum: false,
    album: false,
    meteo: false,
    notes: false,
    dictaphone: false
  })
  const [isFirstBatterie, setIsFirstBatterie] = useState(true)
  const [steps, setSteps] = useState({
    inscriptionShowcase: false,
    findAlbum: false,
    phoneNotification: false
  })
  const [tracks, setTracks] = useState([
    {
        id: 1,
        name: "Track 1",
        author: "Leto",
        file: '/songs/LETO_POWER_SNIP.mp3',
        password: 'Anakin',
        locked: true
    },
    {
        id: 2,
        name: "Track 2",
        author: "Leto",
        file: '/songs/LETO_MAPESSA_SNIP.mp3',
        password: 'Bisou',
        locked: true
    },
    {
        id: 3,
        name: "Track 3",
        author: "Leto",
        file: '/songs/LETO_PETIT_FRERE_SNIP_1.mp3',
        password: 'Badaboum',
        locked: true
    },
    {
        id: 4,
        name: "Track 4",
        author: "Leto",
        file: '/songs/LETO_RIHANNA_SNIP.mp3',
        password: 'MozartcapitaineJackson',
        locked: true
    },
    {
        id: 5,
        name: "Track 5",
        author: "Leto",
        file: '/songs/LETO_TAVAIS_RAISON_SNIP.mp3',
        password: 'PSO',
        locked: true
    }
])

  const lockedScreen = useRef()

  // OPEN APPLICATIONS
  const openPage = (item) => setOpenState(openState => ({ ...openState, [item]: true }))
  const closePage = (item) => setOpenState(openState => ({ ...openState, [item]: false }))
  const closeAllPagesExcept = item => setOpenState({ ...openState, notificationSMS: false, album: false, call: false, [item]: true })

  const [popup, setPopup] = useState({
    title: null,
    text: null
  })

  useInterval(() => {
    if (batterie > 0) {
      setBatterie(batterie - 1);
    }

    if (batterie === 1) {
      setEnd(true)
    }
  }, 30000);

  useEffect(() => {
    if (batterie === 10 || batterie === 5) {
      openPage('batterie')
    }

    if (batterie === 8 && !steps.inscriptionShowcase) {
      openPage('notificationAgenda')

      setTimeout(() => {
        closePage('notificationAgenda')
      }, 5000)
    }
  }, [batterie])

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
    }, 10000)
  }, [])

  return (
      <div className="fullApp">
        <img className="desktopLogo" src={LogoLeto} alt="" />
        <section className="rightActions">
            {
              end && <button onClick={() => window.location.reload()}>Recommencer ?</button>
            }
            <a href="https://leto.lnk.to/PLEM" target="_blank" rel="noreferrer">Écouter en stream</a>
            <a href="https://leto.lnk.to/17pour100-D2C" target="_blank" rel="noreferrer">Voir le store</a>
        </section>
        <div className="App">
        <i>Speaker</i>
        <b>Camera</b>
        <div className="appContent">
        <Popup close={() => {
          if (isFirstBatterie) {
            setIsFirstBatterie(false)
            setTimeout(() => {
              openPage('notificationSMS')
              setTimeout(() => {
                closePage('notificationSMS')
              }, 4000)
            }, 1000)
          }
          closePage('batterie')
        }} isOpen={openState.batterie} title="Batterie faible" text={`Charge restante : ${batterie} %`} /> 
        <Popup close={() => closePage('popup')} isOpen={openState.popup} title={popup.title} text={popup.text} />
          {
            end === false && (
              <>
                {
                  isLoaded && <TopBar batterie={batterie} />
                }
                <Switch>
                  <Route path="/">
                    {isLoaded ?
                      <Homepage
                        openPage={openPage}
                        openCalendrier={() => openPage('calendrier')}
                        openBatterie={() => openPage('batterie')}
                        steps={steps}
                        openPopup={(title, text) => {
                          openPage('popup')
                          setPopup({
                            title,
                            text
                          })
                        }}
                        closePopup={() => closePage('popup')}
                        icons={icons}
                      />
                        :
                      <Introduction />}
                  </Route>
                </Switch>

                <PageTransition stateIn={openState.call}>
                  <Call batterie={batterie} close={() => closePage('call')} />
                </PageTransition>

                <PageTransition stateIn={openState.sms}>
                  <SMS batterie={batterie} close={() => closePage('sms')} />
                </PageTransition>

                <PageTransition stateIn={openState.gallerie}>
                  <Gallerie batterie={batterie} close={() => closePage('gallerie')} />
                </PageTransition>

                <PageTransition stateIn={openState.music}>
                  <Music batterie={batterie} close={() => closePage('music')} />
                </PageTransition>

                <CSSTransition
                    in={openState.dayCalendrier}
                    timeout={350}
                    classNames="fromRight"
                    unmountOnExit
                    appear
                >
                  <DayCalendrier batterie={batterie} steps={steps} setSteps={setSteps} closePage={closePage} openPage={openPage} />
                </CSSTransition>

                <PageTransition stateIn={openState.calendrier}>
                  <Calendrier batterie={batterie} closePage={closePage} openPage={openPage} close={() => closePage('calendrier')} />
                </PageTransition>

                <PageTransition stateIn={openState.maps}>
                  <Maps batterie={batterie} close={() => closePage('maps')} />
                </PageTransition>

                <PageTransition stateIn={openState.notes}>
                  <Notes batterie={batterie} close={() => closePage('notes')} />
                </PageTransition>

                {/* <PageTransition stateIn={openState.lydia}>
                  <Lydia batterie={batterie} close={() => closePage('lydia')} />
                </PageTransition> */}

                <PageTransition stateIn={openState.album}>
                    <AlbumHidden tracks={tracks} setTracks={setTracks} batterie={batterie} closePage={closePage} openPage={openPage} close={() => closePage('album')} />
                </PageTransition>

                <CSSTransition
                    in={openState.notificationAgenda}
                    timeout={350}
                    classNames="notif"
                    unmountOnExit
                    appear
                >
                  <Notification
                    appName="Agenda"
                    icon={AgendaIcon}
                    setOpenState={setOpenState}
                    title="Inscription Soirée 17%"
                    text="Un évènement a été ajouté le 15 septembre à votre calendrier. Inscrivez vous pour tenter de gagner des places."
                    onClick={() => {
                      closePage('notificationAgenda')
                      openPage('calendrier')
                      setSteps((prevState) => ({
                        ...prevState,
                        phoneNotification: false
                    }))
                    }}
                  />
                </CSSTransition>

                <CSSTransition
                    in={openState.easterEgg}
                    timeout={350}
                    classNames="notif"
                    unmountOnExit
                    appear
                >
                  <Notification
                    appName="Agenda"
                    title="Dev"
                    text="Bien vu captain ;)"
                    onClick={() => {
                      closePage('easterEgg')
                    }}
                  />
                </CSSTransition>

                <CSSTransition
                    in={openState.notificationSMS}
                    timeout={350}
                    classNames="notif"
                    unmountOnExit
                    appear
                >
                  <Notification
                    icon={SMSIcon}
                    appName="SMS"
                    title="Fazz"
                    setOpenState={setOpenState}
                    text="Wsh Leto, tout est bon pour la soirée. Ça sera bien le mercredi 15 septembre à 20h au Badaboum, ils sont pas prêts !"
                    onClick={() => {
                      closeAllPagesExcept('sms')
                    }}
                    onMount={() => {
                      setSteps(prevState => ({
                        ...prevState,
                        phoneNotification: true
                      }))
                    }}
                  />
                </CSSTransition>


                <PageTransition stateIn={openState.meteo}>
                  <Meteo batterie={batterie} close={() => closePage('meteo')} />
                </PageTransition>


                <PageTransition stateIn={openState.dictaphone}>
                  <Dictaphone batterie={batterie} close={() => closePage('dictaphone')}  />
                </PageTransition>

              {isLoaded === true && <BottomBar steps={steps} close={(item) => closePage(item)} setSteps={setSteps} open={(item) => openPage(item)} />}
              </>
            )
          }
          {end === true && <EndGame />}
        </div>

          <Footer />
          <img className="break" src={BreakedGlass} alt="" />
        </div>
          <Footer />
      </div>
  );
}

export default App;
