import React, { useEffect } from 'react'
import BackIcon from '../../../assets/icons/crossClose.png'
import TopBar from '../../../components/topBar'
import VisuelSoiree from '../../../assets/img/soiree.jpeg'
import './index.scss'

const DayCalendrier = ({ closePage, openPage, steps, setSteps, batterie }) => {
    useEffect(() => {
        var connect_button = new window.WMGConnect(".wmg-button", {
            opt_in_thank_you_enabled: false
        });
    
        connect_button.setCallback(function(connect_data){
            // Callback code
            setSteps((prevState) => ({
                ...prevState,
                inscriptionShowcase: true
            }))
            console.log(connect_data);
        });
    }, [])

    return (
        <main className="dayCalendrier">
            <TopBar batterie={batterie} color="black" />
            <div className="topCalendrier">
                <img onClick={() => closePage('dayCalendrier')} className="back" src={BackIcon} alt="" />
                <h2>15 septembre</h2>
                <p>Mercredi</p>
            </div>

            <div className="dayCalendrier-content">
                <p className="previousEvent">1 événement de prévu</p>
                <h3>Soirée 17%</h3>
                {
                    steps?.inscriptionShowcase === false && (
                        <>
                            <p className="explication">Rempli le formulaire pour tenter de recevoir une invitation à la Soirée 17% le 15 septembre 2021.</p>
                            <button type="button" className="wmg-button" data-platform="form">S'inscrire</button>
                        </>
                    )
                }
                {
                    steps?.inscriptionShowcase === true && (
                        <p className="info">Vous êtes bien inscrit. Nous vous recontacterons si vous avez gagné.</p>
                    )
                }
            </div>
        </main>
    )
}

export default DayCalendrier