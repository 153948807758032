import React from 'react'
import './index.scss'

const FakeIcon = ({ openPopup, closePopup, icon }) => {
    return (
        <article onClick={() => openPopup(icon.popupTitle, icon.popupText)} className={`fakeIconContainer`}>
            <div className="fakeIcon">
                <img src={icon.img} alt="" />
            </div>
            <p className="title">{icon.name}</p>
        </article>
    )
}

export default FakeIcon