import React from 'react'
import Reseau from '../../assets/icons/ios-reseau.png'
import moment from 'moment'
import 'moment/locale/fr';
import './index.scss'

const TopBar = ({ batterie, color }) => {
    return (
        <div className={`topBar ${color ? color: ''}`}>
            <p>{moment().locale('fr').format('LT')}</p>

            <div className="right_topBar">
                <img src={Reseau} alt="" />
                <p className="reseau">4G</p>
                {<p className="batterie">{batterie}%</p>}
                <div className="external_battery">
                    <div style={{
                        width: batterie + "%"
                    }} className="internal_battery"></div>
                </div>
            </div>
        </div>
    )
}

export default TopBar