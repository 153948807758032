import React from 'react'
import VoidIcon from '../voidIcon'
import FolderIcon from '../folderIcon'
import './index.scss'
import CalendrierIcon from '../calendrierIcon'
import MapsIcon from '../../assets/icons/maps.png'
import GallerieIcon from '../../assets/icons/gallerie.png'
import MeteoIcon from '../../assets/icons/weather.png'
import FakeIcon from '../fakeIcon'
import NotesIcon from '../../assets/icons/note.png'
import DictaphoneIcon from '../../assets/icons/dictaphone.png'
import Cover from '../../assets/icons/dixsept.png'
import LydiaIcon from '../../assets/icons/lydia.png'

const FolderContainer = ({ steps, icons, setCurrentFolder, openCalendrier, openPopup, closePopup, openPage }) => {
    return (
        <section className="folderContainer">
            {
                icons.map((icon) => {
                    if (icon.type === "folder") {
                        return <FolderIcon  setCurrentFolder={setCurrentFolder} key={icon.id} icon={icon} />
                    } else if (icon.type === "void") {
                        return <VoidIcon key={icon.id} />
                    } else if (icon.type === "calendrier") {
                        return <CalendrierIcon steps={steps} openCalendrier={openCalendrier} key={icon.id} icon={icon} />
                    } else if (icon.type === "fake") {
                        return <FakeIcon key={icon.id} icon={icon} closePopup={closePopup} openPopup={openPopup} />
                    } else if (icon.type === "gallerie") {
                        return (
                            <article key={icon.id} className="folderIcon">
                                <img onClick={() => openPage('gallerie')} src={GallerieIcon} alt="" />
                                <p>Photos</p>
                            </article>
                        )
                    } else if (icon.type === "maps") {
                        return (
                            <article key={icon.id} className="folderIcon">
                                <img onClick={() => openPage('maps')} src={MapsIcon} alt="" />
                                <p>Maps</p>
                            </article>
                        )
                    } else if (icon.type === "lockedAlbum") {
                        return (
                            <article key={icon.id} className="folderIcon">
                                <img className="radiusIcon" onClick={() => openPage('album')} src={Cover} alt="" />
                                <p>17%</p>
                            </article>
                        )
                    } else if (icon.type === "meteo") {
                        return (
                            <article key={icon.id} className="folderIcon">
                                <img className="radiusIcon" onClick={() => openPage('meteo')} src={MeteoIcon} alt="" />
                                <p>Météo</p>
                            </article>
                        )
                    } else if (icon.type === "lydia") {
                        return (
                            <article key={icon.id} className="folderIcon">
                                <img className="radiusIcon" onClick={() => openPage('lydia')} src={LydiaIcon} alt="" />
                                <p>Lydia</p>
                            </article>
                        )
                    } else if (icon.type === "notes") {
                        return (
                            <article key={icon.id} className="folderIcon">
                                <img className="radiusIcon" onClick={() => openPage('notes')} src={NotesIcon} alt="" />
                                <p>Notes</p>
                            </article>
                        )
                    } else if (icon.type === "dictaphone") {
                        return (
                            <article key={icon.id} className="folderIcon">
                                <img className="radiusIcon" onClick={() => openPage('dictaphone')} src={DictaphoneIcon} alt="" />
                                <p>Dictaphone</p>
                            </article>
                        )
                    } else {
                        return null
                    }
                })
            }
        </section>
    )
}

export default FolderContainer